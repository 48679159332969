<template>
	<div class="home">
		<subsidiary-request></subsidiary-request>
	</div>
</template>

<script>
import subsidiary from '@/components/pages/report/subsidiary/subsidiaryRequest.vue';

export default {
	components: {
		'subsidiary-request': subsidiary,
	}
};
</script>

<style>

</style>
