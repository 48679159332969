<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="10" sm="10">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
			<v-card>
				<v-card-title align="left" class="headline primary report-title">
					<h5>Subsidiary Request</h5>
				</v-card-title>
				<br>
				<v-container>
					<v-layout wrap>
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Account Type:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3>
						<v-select
							v-model="account_type"
							:items="account_types"
							data-vv-name="account_types"
							dense
							required
							maxlength=1
						></v-select>
						</v-flex>
						<v-flex md1 />
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Account Code:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3 v-if="account_type == 1">
							<v-autocomplete
								placeholder="Parent Account"
								class="remove-underline"
								v-model="item.account_parent"
								dense
								:items="dataCoasParent"
								hide-details
								item-text="item_code"
								item-value="acct_code"
								:rules="[v => !!v || 'Please select Parent Account']"
							>
							</v-autocomplete>
						</v-flex>
						<v-flex xs6 sm4 md3 v-else>
							<v-autocomplete
								placeholder="Account"
								class="remove-underline"
								v-model="item.account_code"
								dense
								:items="coaData"
								hide-details
								item-text="code_name"
								item-value="acct_code"
								:rules="[v => !!v || 'Please select Account Code']"
							></v-autocomplete>
						</v-flex>
					</v-layout>
					<v-layout wrap>  
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Department:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3>
						<v-autocomplete
							placeholder="Department"
							class="remove-underline"
							v-model="item.department_id"
							dense
							:items="dataDepartments"
							v-on:focus="getDepartments"
							hide-details
							item-text="dept_desc"
							item-value="id"
						></v-autocomplete>
						</v-flex>
						<v-flex md1 />
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Branch:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3>
							<v-autocomplete
								placeholder="Branch"
								class="remove-underline"
								v-model="item.branch_id"
								dense
								:items="dataBranches"
								v-on:focus="getBranches"
								hide-details
								item-text="code_name"
								item-value="id"
							></v-autocomplete>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Date From:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3>
							<v-menu
								v-model="from_date"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="item.date_from"
										required
										dense
										:rules="date_rules"
										return-masked-value
										v-on="on"
										mask="YYYY-MM-DD"
										placeholder="YYYY-MM-DD">
									</v-text-field>
								</template>
								<v-date-picker
									color="primary"
									v-model="item.date_from"
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex md1 />
						<v-flex xs6 sm3 md2>
							<v-subheader>
								<h3>Date To:</h3>
							</v-subheader>
						</v-flex>
						<v-flex xs6 sm4 md3>
						<v-menu
							v-model="to_date"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="item.date_to"
									required
									dense
									:rules="date_rules"
									return-masked-value
									v-on="on"
									mask="YYYY-MM-DD"
									placeholder="YYYY-MM-DD">
								</v-text-field>
							</template>
							<v-date-picker
								color="primary"
								v-model="item.date_to"
							></v-date-picker>
						</v-menu>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs6 sm3 md2>
						<v-subheader>
							<h3>Sort By:</h3>
						</v-subheader>
					</v-flex>
					<v-flex xs6 sm4 md9>
						<v-radio-group v-model="item.sort_by" row dense>
							<v-radio label="Payee" value="payee"></v-radio>
							<v-radio label="Department" value="department"></v-radio>
						</v-radio-group>
					</v-flex>
				</v-layout>
				<br>
				<v-layout>
					<v-flex md1 />
					<v-btn dark color="blue" width="200" @click="generate">Generate</v-btn>
				</v-layout>
            </v-container> 
            <br>
            <br>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data: () => ({
		year: [],
		item: {
			account_code: '',
			department_id: 'ALL',
			branch_id: 'ALL',
			date_from: '',
			date_to: '',
			sort_by: 'payee'
		},
		from_date: false,
		to_date: false,
		date_rules: [
			v => !!v || 'Date is required',
		],
		nextPageDepts: '',
		dataDepts: [],
		dataCoas: [],
		dataCoasParent: [],
		nextPageCoasParent: '',
		dataDepartments: [{id: 'ALL', dept_desc: 'All Departments'}],
		dataBranches: [{id: 'ALL', code_name: 'All Branches'}],
		nextPageCoas: '',
		isvalid: false,
		default_rules: [
			v => !!v || 'Required',
		],
		account_type: 0,
		account_types: [
			{ value: 0, text: 'Subsidiary Account'},
			{ value: 1, text: 'Parent Account'}
		],
	}),
	computed: {
		...mapGetters({
			periodNo: 'acctperiodmain/periodNo',
			coas: 'coa/coaSubs',
			depts: 'dept/depts',
			branches: 'branch/branches',
			coaData: 'coa/coaData',
			coasParent: 'coa/coas',
		})
	},
	watch: {
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		coasParent: {
			handler(){
				this.getDataCoasParent();
			}
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
	},
	mounted(){
		this.getYear();
		this.getCoas();
		this.getBranch();
		this.getDepts();
	},
	methods: {
		getDepartments(){
			this.dataDepts.map(det => {
				this.dataDepartments.push(det);
			});
		},
		getBranches(){
			this.branches.map(det => {
				this.dataBranches.push(det);
			});
		},
		getCoas(){
			this.$store.dispatch('coa/getCoaDropdown');
			this.$store.dispatch('coa/getCoas');
		},
		getBranch() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		getDepts() {
			this.$store.dispatch('dept/getDeptDropdown');
		},
		getYear() {
			let data = [];
			var i;
			data.push({value: '2020', text: '2020'});
			for (i = 1; i < 5; i++) {
				let det = 2020 - i;
				det.toString();
				data.push({value: det, text: det});
			}
			this.year = data;
		},
		generate() {
			this.isvalid = this.$refs.form.validate();
			if(this.account_type === 0){
				delete this.item.account_parent;
			} else {
				delete this.item.account_code;
			}

			if (this.isvalid) {
				let routeData = this.$router.resolve({path: '/report-subsidiary/'+btoa(JSON.stringify(this.item)), data: this.item});
				window.open(routeData.href, '_blank'); 
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoasParent(){
			this.dataCoasParent = this.coasParent.data.filter(det => det.acct_type === 'P');
			this.nextPageCoasParent = this.coasParent.next_page_url;
			let page = 2;

			this.dataCoasParent.forEach(det => {
				det.item_code = det.acct_code + ' - ' + det.acct_desc;
			});

			while (this.nextPageCoasParent !== null){
				await this.$store.dispatch('coa/getDataPages', page).then(response => {
					this.nextPageCoasParent = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						if(details.account_type === 'P'){
							details.item_code = details.acct_code + ' - ' + details.acct_desc;
							this.dataCoasParent.push(details);
						}
					});
				});
				page++;
			}
		},
	}
};
</script>
<style>
.report-title {
	color: #FFFFFF;
}
</style>